import { getAttributionCookieValue } from '@pretto/app-core/lib/attributionCookie'

import * as P from '@pretto/picto'
import { useEmail } from '@pretto/website/src/components/Fields/email'
import { matomoTrackEvent } from '@pretto/website/src/lib/matomoTrackEvent'
import { BlocRow } from '@pretto/website/src/templates/blocs/components/BlocRow'
import { InputBannerRow as InputBannerRowComponent } from '@pretto/website/src/templates/blocs/components/inputBannerRow/InputBannerRow'
import { convertToTrackingFormat } from '@pretto/website/src/templates/blocs/lib/convertToTrackingFormat'
import type { Promise } from '@pretto/website/src/templates/blocs/types/promisies.type'

import { useState } from 'react'

const STATUS_CODE_OK = 200
const DEFAULT_ERROR_MESSAGE = 'Désolé, une erreur est survenue. Nous vous invitons à contacter notre service client.'

type InputBannerRowProps = {
  bannerId?: string
  illustration: string
  inputPlaceholder: string
  isHero: boolean
  promises?: Promise[]
  successMessage?: string
  text: string
  title: string
  zap: string
}

export const InputBannerRow: React.FC<InputBannerRowProps> = ({
  bannerId,
  illustration,
  inputPlaceholder,
  isHero,
  promises,
  successMessage,
  text,
  title,
  zap,
}) => {
  const [successMessageToDisplay, setSuccessMessageToDisplay] = useState<string>('')

  const { debouncedError, defaultErrorMessage, email, error, onChange } = useEmail()

  const attributionCookie = getAttributionCookieValue()

  const formatPromises =
    promises && promises.length > 0 ? promises.map(({ picto, ...promise }) => ({ picto: P[picto], ...promise })) : []

  const handleSubmit = async () => {
    const id = bannerId ? convertToTrackingFormat(bannerId) : null

    const response = await fetch(zap, {
      body: JSON.stringify({ attribution_cookie: attributionCookie, email, id }),
      method: 'POST',
    })

    if (response.status === STATUS_CODE_OK) {
      setSuccessMessageToDisplay(successMessage ?? '')
      matomoTrackEvent({ category: 'Prelead', action: `Signup with ${id}}`, name: email })

      setTimeout(() => {
        setSuccessMessageToDisplay('')
      }, 3000)
    } else {
      setSuccessMessageToDisplay(DEFAULT_ERROR_MESSAGE)

      setTimeout(() => {
        setSuccessMessageToDisplay('')
      }, 3000)
    }
  }

  const emailInputProps = {
    email,
    inputProps: {
      placeholder: inputPlaceholder,
    },
    isButtonDisabled: !!error,
    isMessageTips: true,
    isSingle: true,
    message: !error ? '' : debouncedError && defaultErrorMessage,
    onChange,
    onSubmit: handleSubmit,
    state: !email ? null : debouncedError ? 'error' : null,
  }

  const props = {
    emailInputProps,
    illustration,
    isHero,
    promises: formatPromises,
    successMessage: successMessageToDisplay,
    text,
    title,
  }

  return (
    <BlocRow isHero={isHero}>
      <InputBannerRowComponent {...props} />
    </BlocRow>
  )
}
