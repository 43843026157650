import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { PromiseFormated } from '@pretto/website/src/templates/blocs/types/promisies.type'

import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import { ThemeProvider } from 'styled-components'

import * as S from './InputBannerRow.styles'

type InputBannerRowProps = {
  emailInputProps: object
  illustration: string
  isHero: boolean
  promises: PromiseFormated[]
  successMessage: string
  text: string
  title: string
}

export const InputBannerRow: React.FC<InputBannerRowProps> = ({
  emailInputProps,
  illustration,
  isHero,
  promises,
  successMessage,
  text,
  title,
}) => (
  <ThemeProvider theme={{ isHero, withPromises: promises.length > 0 }}>
    <S.InputBanner>
      <S.Content>
        <S.Title>{title}</S.Title>

        <S.Text>{text}</S.Text>

        <S.Email {...emailInputProps} />

        <TransitionGroup>
          {successMessage && (
            <CollapsibleRow key={successMessage}>
              <S.SuccessMessage>{successMessage}</S.SuccessMessage>
            </CollapsibleRow>
          )}
        </TransitionGroup>
      </S.Content>

      <S.IllustrationContainer>
        <S.Illustration path={illustration} />
      </S.IllustrationContainer>
    </S.InputBanner>

    {promises.length > 0 && (
      <S.ReasonToBelieveContainer>
        <S.ReasonToBelieve promises={promises} />
      </S.ReasonToBelieveContainer>
    )}
  </ThemeProvider>
)
