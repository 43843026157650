import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ReasonToBelieve = ({ promises, ...props }) => (
  <S.Container {...props}>
    {promises.map(({ picto, promise, title }, index) => (
      <S.CurrentPromise key={index}>
        <S.Picto as={picto} />
        <S.Title>{title}</S.Title>
        <S.Description>{promise}</S.Description>
      </S.CurrentPromise>
    ))}
  </S.Container>
)

ReasonToBelieve.propTypes = {
  promises: PropTypes.arrayOf(
    PropTypes.shape({
      picto: PropTypes.elementType.isRequired,
      promise: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}

export default memo(ReasonToBelieve)
