import BaseInput from '@pretto/bricks/components/form/BaseInput'
import { shape } from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'
import BaseReasonToBelieve from '@pretto/bricks/website/shared/components/ReasonToBelieve'

import styled, { css } from 'styled-components'

export const InputBanner = styled.div`
  padding: ${g(4)} ${g(3)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  position: relative;
  z-index: 1;
  border-radius: ${g(1)};
  border-bottom-left-radius: ${({ theme: { withPromises } }) => (withPromises ? 0 : g(1))};
  border-bottom-right-radius: ${({ theme: { withPromises } }) => (withPromises ? 0 : g(1))};
  width: 100%;

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(6)} 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${g(3)};
    justify-items: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: ${g(7.5)} 0;
  }

  ${({ theme: { withPromises } }) =>
    !withPromises &&
    css`
      overflow: hidden;

      ${shape(
        css`
          width: 500px;
          height: 573px;
          background-size: 500px 573px;
          transition: 250ms transform ease-in-out;
          transform: translate(202px, 405px);
          top: initial;
          left: initial;
        `,
        {
          variant: 'primary2',
          mediaQueries: {
            laptop: css`
              width: 868px;
              height: 994px;
              background-size: 868px 994px;
              transform: translate(326px, 720px);
            `,
            desktop: css`
              transform: translate(294px, 650px);
            `,
          },
          hover: {
            laptop: css`
              transform: translate(334px, 730px);
            `,
            desktop: css`
              transform: translate(304px, 660px);
            `,
          },
        }
      )}
    `}
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 100%;
  grid-row: 1 / span 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2 / span 6;
  }
`
export const Title = styled.div.attrs(({ theme: { isHero } }) => ({ as: isHero ? 'h1' : 'div' }))`
  ${typo.heading32}
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading48}
    margin-bottom: ${g(3)};
  }
`

export const Text = styled.p`
  margin-bottom: ${g(3)};
`

export const Email = styled(BaseInput)``

export const SuccessMessage = styled.div`
  padding-top: ${g(1)};
`

export const IllustrationContainer = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1 / span 1;
    grid-column: 9 / span 3;
  }
`

export const Illustration = styled(ImageComponent).attrs({
  options: { width: 300, height: 300 },
})`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    width: 250px;
    height: 250px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
    width: 300px;
    height: 300px;
  }
`

export const ReasonToBelieveContainer = styled.div`
  ${grid()};
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;
    background-color: ${({ theme }) => theme.colors.primary1};
    border-bottom-left-radius: ${g(1)};
    border-bottom-right-radius: ${g(1)};
    z-index: 1;

    @media screen and (min-width: ${breakpoints.tablet}) {
      height: 40%;
    }
  }
`

export const ReasonToBelieve = styled(BaseReasonToBelieve)`
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral1};
  z-index: 2;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`
