import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  display: grid;
  grid-gap: ${g(3)};
  grid-template: repeat(3, auto) / auto;
  padding: ${g(5)} ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-gap: ${g(7)};
    grid-template: auto / repeat(3, 1fr);
    padding: ${g(3)} ${g(5)};
  }
`

export const CurrentPromise = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: grid;
    grid-gap: ${g(1)} ${g(2)};
    grid-template: 'picto title' auto 'picto description' 1fr / ${g(4)} 1fr;
    text-align: left;
  }
`

export const Picto = styled.svg`
  display: block;
  height: ${g(4)};
  margin-bottom: ${g(2)};
  margin-left: auto;
  margin-right: auto;
  width: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-area: picto;
  }
`

export const Description = styled(BaseTypo).attrs({ forwardedAs: 'p', typo: 'caption12' })`
  grid-area: description;
`

export const Title = styled(BaseTypo).attrs({ typo: 'headline16' })`
  grid-area: title;
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`
